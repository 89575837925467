/* questionnaire builder css style start here */
.qb-type-heading {
  @apply bg-card-500 h-12 flex items-center p-4;
}
.qb-type-heading label {
  @apply text-font-light text-base font-bold m-0;
}
.new-type-info {
  @apply px-7 bg-card-600;
}
.drag-block {
  @apply absolute flex justify-center  items-center left-0 top-0 h-full cursor-pointer;
  width: 25px;
}
.qb-menu-active .drag-block svg {
  @apply block;
}
.qb-menu-active .drag-block {
  /* background-color: #c8dff1; */
  @apply bg-primary-400;
}
.drag-block svg {
  @apply hidden;
}
.drag-block:hover {
  /* background-color: #c8dff1; */
  @apply bg-primary-400;
}
.drag-block:hover svg {
  @apply block cursor-move;
}

.qb-header {
  @apply bg-card-800 grid md:grid-cols-2 py-8 px-6 md:px-16 shadow relative w-full top-0 z-40;
  min-height: 150px;
}
.qb-heading-info {
  @apply flex items-baseline;
}
.qb-heading-info h3 {
  @apply mb-2 text-lg lg:text-lg xlg:text-xl text-secondary-100;
}
.qb-heading-info span {
  @apply my-0 mx-1 cursor-pointer;
}
.output-group {
 height: 50px;
}

.complete-group {
  height: 100px;
 }

 .h-grp {
  height: 80px !important;
 }

.output-group h4, .complete-group h4 {
  @apply pr-2 mb-1.5 text-font-300 text-base font-proximanovaRegular;
}

.output-group p, .complete-group p {
  @apply pr-2 mb-1.5 text-font-300 text-base font-proximanovaBold;
}
.output-group p:last-child, .complete-group p:last-child{
  @apply p-0;
}
.output-group:last-child p, .complete-group:last-child p{
  @apply mb-0;
}
.output-group p:first-child, .complete-group p:first-child{
  @apply whitespace-nowrap;
}
.form-setting {
  @apply flex md:items-end flex-col mt-3 md:mt-0;
}
.save-form-block {
  @apply flex items-center justify-between md:block;
}
.qb-add-section {
  @apply md:col-span-1 bg-card-700 md:z-20 overflow-y-auto max-h-screen min-h-full h-full;
}
.qb-menu-info h1,
.qb-menu-info h2,
.qb-menu-info h3,
.qb-menu-info h4,
.qb-menu-info h5 {
  @apply text-base font-proximanovaBold;
}
.menu-right {
  @apply flex justify-end flex-wrap;
}
.menu-right div.link-menu:first-child {
  @apply mr-2;
}
.choose-input-group {
  @apply my-6;
}
.choose-tag-body {
  @apply p-4 border border-secondary-100;
  max-height: 110px;
}
.choose-input-group label:first-child {
  @apply text-sl block mb-3 font-bold text-left tracking-wide text-secondary-100;
}
.popup-body form {
  @apply px-7;
}

.qb-type-section {
  @apply bg-white h-full md:col-span-2;
}

.qb-type-info {
  @apply flex items-center justify-between py-3 px-4 border-b border-secondary-100 mb-1;
}
.qb-type-info span {
  @apply text-sm font-normal;
}
.qb-start-msg {
  @apply bg-card-600 py-6 xx:py-7 px-6 xx:px-12 mt-8;
}
.qb-start-msg p {
  @apply text-md font-medium text-font-text;
}
.qb-start-msg span {
  @apply hidden md:inline-block;
}
.qb-start-msg span:last-child {
  @apply inline-block md:hidden;
}
.qb-footer-submit {
  @apply flex flex-col gap-5 xs:flex-row items-center justify-center xs:justify-between px-8 bg-white;
  min-height: 80px;
}
.qb-footer-submit button {
  @apply my-3 xs:my-auto min-w-fit;
}
@media (max-width: 420px) {
  .qb-footer-submit button:last-child {
    @apply mt-0 ml-0;
  }
  .qb-footer-submit button:first-child {
    @apply mb-0 ml-0;
  }
}
.toggle-block {
  @apply flex justify-between;
}
.question-add-block {
  @apply px-8;
}
.qb-submit-section {
  /* bottom: 52px;
  @apply fixed md:bottom-0 w-full z-30 md:z-10; */
  @apply w-full;
}
.approval-submit-block {
  @apply px-5;
}
.approval-submit-block .input-group label {
  @apply text-md;
}
.Qb-setting-toggle {
  @apply mb-4;
}
.sub-question-block {
  @apply bg-card-700 rounded-tl-sm rounded-tr-sm;
  min-height: 200px;
}
.sub-question-heading {
  @apply bg-primary-500 rounded-tl-sm rounded-tr-sm flex items-center;
  min-height: 50px;
}
.sub-question-heading label {
  @apply text-font-light ml-6 font-bold;
}
.sub-question-body {
  @apply px-6 pb-0.5;
}
/* questionnaire builder css style start here */

/* Forms & Questionnaires End */

/* Responses Start */

.qb-full-card {
  @apply grid grid-cols-1 md:grid-cols-2 bg-primary-300 py-7 px-9 rounded-md;
  min-height: 180px;
}
.qb-full-card h3 {
  @apply mb-2 text-secondary-100;
}
.qb-full-card .output-group {
  @apply pl-0;
}
.responses-section {
  @apply flex justify-between items-start flex-col-reverse mt-2 md:items-end md:flex-col;
}
.responses-count-block {
  @apply flex;
}
.responses-count-info:first-child {
  @apply mr-7;
}
.responses-count-info span {
  @apply block text-xl text-secondary-100 text-left md:text-right font-bold;
}
.responses-count-info p {
  @apply text-base text-secondary-100 text-left md:text-right;
}
.responses-view-block {
  @apply my-4 mx-auto;
  max-width: 700px;
}
.view-data-group {
  @apply mb-7;
}
.required::after {
  content: "*";
  padding-left: 2px;
  color: red;
}

.view-data-group > pre:first-child,
.view-data-group > label:first-child,
.view-data-group .input-group label,
.view-data-group .dropdown-label {
  @apply text-lg md:text-md font-proximanovaBold mb-4 inline-block text-font-text whitespace-normal;
}
.view-data-group p {
  @apply text-base font-normal text-font-text font-proximanovaRegular mb-4;
}
.view-data-group h1,
.view-data-group h4,
.view-data-group h5 {
  @apply text-font-text font-proximanovaBold text-lg !important;
}

.view-data-group h2 {
  @apply text-font-text font-proximanovaBold text-md;
}

.view-data-group h3 {
  @apply text-font-text font-proximanovaBold text-base !important;
}

.view-data-group textarea {
  @apply w-full border py-2 px-4 border-secondary-100 relative bg-transparent text-sm tracking-wide rounded-md text-secondary-100 focus:outline-none;
  box-sizing: border-box;
  letter-spacing: 1px;
}
.view-data-group .star-rating-img img {
  @apply w-8 h-8;
}
.range-block {
  @apply flex items-center h-5;
}
.range-slider-info {
  @apply flex justify-between mt-2;
}
.range-label-wrapper {
  @apply flex items-baseline justify-between;
}
.range-label-wrapper span {
  @apply mt-2 relative  flex justify-center text-sm md:text-base;
}
.range-label-wrapper span::before {
  @apply bg-teritory-200;
  content: "";
  width: 2px;
  height: 7px;
  position: absolute;
  top: -7px;
}
.statment-block {
  @apply text-md md:text-lg text-font-dark break-words italic font-bold;
}
/* Responses End */

/* Dashboard start */
.dashboard-summary-info p {
  @apply text-lg xx:text-1xl mb-5;
}
.dashboard-summary-info span {
  @apply text-2xl xx:text-4xl font-bold text-secondary-100 block;
}
/* Dashboard End */

.timeup-icon {
  @apply absolute right-1 px-1.5 cursor-pointer text-primary-500;
  height: 20px;
  bottom: 0px;
}
.timeup-icon svg {
  margin-top: -5px;
}
.timedown-icon {
  @apply absolute right-1 px-1.5 cursor-pointer text-primary-500;
  height: 20px;
  top: 0;
  bottom: 14px;
}

/* Picture choice */
.add-image-section {
  @apply grid sm:grid-cols-2 xl:grid-cols-3 gap-4;
}
.upload-btn-block {
  @apply gap-3 flex justify-end flex-wrap;
}

/* form approvals */

.share-qb-block {
  @apply flex items-center border border-secondary-100 px-7 py-5 cursor-pointer rounded-sm;
  min-height: 145px;
}
.share-qb-block label {
  @apply text-1xl text-secondary-100 font-bold block mb-2 cursor-pointer;
}
.share-qb-block svg {
  @apply mr-8;
  fill: #3b8282;
}
.back-btn {
  @apply inline-flex items-center cursor-pointer;
}
.back-btn svg {
  @apply mr-3;
}
.circle-sharelist-block {
  @apply px-10 py-11 bg-card-800;
}
.submission-select-count {
  @apply flex items-end justify-end;
}
.submission-select-count label {
  @apply text-1xl font-bold;
}
.recieved-form-block {
  @apply grid xx:grid-cols-2 gap-6 mt-7;
}
.right-btn-group .btn:nth-child(2),
.fm-btn-group .btn:nth-child(2),
.swe-btn-block .btn:nth-child(2) {
  @apply ml-0;
}
.recieved-form-block .card-info {
  @apply flex justify-between flex-col;
  height: 100%;
}
/* Patient Flow */
.full-view-line {
  @apply border-t border-secondary-100 mt-4 -mx-5 pr-5;
}
.qb-card.cursor-pointer:hover {
  @apply bg-card-600;
}
.card-info.cursor-pointer:hover {
  @apply bg-primary-500;
  min-height: 199px;
}
.qb-card.cursor-pointer:hover h5,
.qb-card.cursor-pointer:hover p,
.qb-card.cursor-pointer:hover a,
.mini-card-hover:hover h5,
.mini-card-hover:hover p,
.mini-card-hover:hover a {
  @apply text-secondary-100;
}
.qb-card.active {
  @apply bg-primary-500;
}

.qb-card.active h5,
.qb-card.active p,
.qb-card.active a {
  @apply text-font-light;
}
.free-text-icon rect {
  @apply text-primary-500 fill-current !important;
}
.multiple-choice-icon path {
  @apply text-primary-500 fill-current !important;
}
.statement-icon path {
  @apply text-primary-500 fill-current !important;
}
.picture-choice-icon path {
  @apply text-primary-500 fill-current !important;
}
.yes-or-no-icon path {
  @apply text-primary-500 fill-current !important;
}
.rating-icon path {
  @apply text-primary-500 fill-current !important;
}
.linkert-icon path {
  @apply text-primary-500 fill-current !important;
}
.drop-down-icon path {
  @apply text-primary-500 fill-current !important;
}
.section-icon path {
  @apply text-primary-500 fill-current !important;
}
.section-icon rect {
  @apply text-primary-500;
}
.opening-statement-icon path {
  @apply text-primary-500 fill-current !important;
}
.close-statement-icon path {
  @apply text-primary-500 fill-current !important;
}
.setting-svg-icon circle,
.delete-svg-icon circle {
  @apply text-primary-500 fill-current !important;
}
.drag-drop-icon path,
.drag-drop-icon rect {
  @apply text-primary-500 fill-current !important;
}
.date-picker-icon path {
  @apply text-primary-500 fill-current !important;
}
.schedule-form-icon path,
.email-form-icon path,
.share-form-icon path {
  @apply text-primary-500 fill-current !important;
}
.toast-bg {
  @apply bg-primary-500 !important;
}

.overall-range {
  @apply flex border-[1px] border-teritory-200 w-[100px] p-2;
  border-radius: 5px;
}

.overall-range svg{
  @apply w-[15px];
}

.minstyle {
  @apply relative;
  left: 0;
}

.maxstyle {
  @apply relative;
  right: 0;
}

.inputnor {
  @apply text-center w-full;
}

.none {
  @apply hidden;
}

.bmi-col{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 0.1fr));
  grid-gap: 16px;
}

.bmitrigger .text-field{
@apply py-3 !important;
}

.phonecall h1{
  margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    display: block;
    text-align: left;
    font-family: proxima-nova-Bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.025em;
    --tw-text-opacity: 1;
    color: rgb(7 47 51 / var(--tw-text-opacity));
}
.qb-question-blk{
  @apply !font-proximanovaBold !text-[18px];
}
.asterisk {
  font-size: 30px !important;
}