@import url('https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://p.typekit.net/p.css?s=1&k=vdf0egm&ht=tk&f=139.140.175.176&a=82781810&app=typekit&e=css');

@font-face {
  font-family: 'proxima-nova-Bold';
  src: url('https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova-Bold-Itlaic';
  src: url('https://use.typekit.net/af/4de20a/00000000000000007735e604/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4de20a/00000000000000007735e604/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/4de20a/00000000000000007735e604/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova-Regular';
  src: url('https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova-Itlatic';
  src: url('https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

* {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  outline: none;
}

*:hover,
*:active,
*:focus {
  outline: none;
}

body {
  font-family: 'proxima-nova-Regular';
}
/* .client-block{
  height: 100vh;
  overflow: hidden;
} */

/* custom scroll bar  */
::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc;
}
/* End of custom scroll bar */

font {
  font-family: 'Sanchez', serif;
}

#root {
  @apply relative;
}

#webpack-dev-server-client-overlay {
  display: none;
}

@font-face {
  font-family: 'SanchezsemiBold';
  src: url('./assets/fonts/Sanchez-SemiBold.eot');
  src: local('Sanchez SemiBold'), local('Sanchez-SemiBold'),
    url('./assets/fonts/Sanchez-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Sanchez-SemiBold.woff') format('woff'),
    url('./assets/fonts/Sanchez-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

.w-60 {
  @apply w-[60%];
}

.overall-bg {
  @apply min-h-screen bg-white;
}

.align-center {
  text-align: center;
}
.table-container {
  @apply max-w-5xl m-auto flex w-[100%] md:w-[100%];
  min-height: calc(100vh - 143px);
}

.table-spacing {
  @apply my-12 mr-5 ml-5 w-[100%];
}

input::-webkit-caps-lock-indicator {
  content: none;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

.react-time-picker__button,
.react-time-picker__clock {
  @apply hidden !important;
}

.react-time-picker__wrapper {
  @apply border-none !important;
}

.mediaclass {
  @apply md:h-[220px] h-[280px];
}

.mediaclassClient {
  @apply h-[450px];
}

.react-time-picker--disabled {
  background-color: unset !important;
}

.react-time-picker--disabled input {
  min-width: unset !important;
  background-color: unset !important;
  text-transform: none !important;
}

.db-head .text-field,
.input-group .text-field:disabled {
  text-transform: none !important;
}

[contenteditable='true']:focus {
  outline: none;
}
.card-head-block h5.text-edit-inline {
  text-overflow: unset !important;
}

h5.text-edit-inline {
  padding: 5px;
  border: 2px solid #009de0;
  border-radius: 4px;
}
.overlay-logout {
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  position: fixed;
  background-color: #fffdfdba;
  z-index: 100;
}

.form-errors-msg {
  @apply text-sm text-secondary-300 !important;
}

.static-form-question {
  @apply flex;
}

.static-form-question span {
  font-size: 30px !important;
  padding-left: 5px;
  padding-top: 5px;
}

.asterik-font {
  font-size: 30px !important;
}

.reset-parent p{
   margin-bottom: 0 !important;
}

.reset-parent li {
  padding-left: 18px;
}

.reset-parent a {
  margin-left: 48px;
  margin-top: 10px;
}

.reset-parent ul{
    padding: 0px 30px 5px 30px;
    list-style: disc;
}

.gp-subtext {
    font-size: 14px !important;
}