@tailwind utilities;

/* Internet Online and Offline */

.net-status-block {
  @apply flex p-4 justify-between items-center fixed bottom-5 left-5 rounded-sm gap-3;
  min-width: 329px;
  min-height: 68px;
  background-color: #242526;
}
.net-status-block p {
  @apply text-font-light;
}
.net-status-block svg {
  fill: #ffffff;
  opacity: 0.3;
}
.net-status-block .OnlineIcon svg {
  fill: #5ac720;
  opacity: 1;
}
.net-status-block .closeIcon {
  @apply cursor-pointer;
}
.page-refresh {
  @apply text-secondary-100 cursor-pointer;
}

/* Internet Online and Offline end here */
/*
input[type='password'] {
  font-family: Verdana;
} */

.flex-90 {
  flex: 0 0 90px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @apply border border-primary-100;
  -webkit-text-fill-color: rgb(131, 131, 131);
  -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  transition: background-color 5000s ease-in-out 0s;
}

svg:focus {
  outline: none;
}
.__react_component_tooltip p {
  @apply text-font-light !important;
}
/* Text Editor start here*/
.rdw-dropdown-wrapper a {
  text-decoration: none !important;
  color: #000 !important;
  font-size: 14px !important;
}
.rdw-option-wrapper {
  min-width: 30px !important;
  height: 30px !important;
}
.qb-text-editor {
  min-height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}
.qb-texteditor-wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
  min-height: 275px !important;
}
.rdw-colorpicker-option {
  box-shadow: none !important;
}
.rdw-colorpicker-modal-options {
  overflow-x: hidden;
  overflow-y: auto;
}
.rdw-image-modal,
.rdw-emoji-modal {
  left: unset !important;
  right: 5px;
}
.hide-menu {
  @apply hidden !important;
}

.quill {
  @apply mt-8;
}

.ql-toolbar {
  @apply bg-teritory-300;
  min-height: 45px !important;
}
.ql-editor {
  width: 100% !important;
  display: block !important;
  min-height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}
/* Text Editor End here */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  @apply bg-white;
}

@responsive {
  .text-small h3 {
    @apply text-base text-primary-100 !important;
  }
}

.web {
  @apply bg-white;
}

* {
  @apply m-0 font-proximanovaRegular;
}
.body-container {
  @apply bg-white;
}

/* li {
  @apply mb-4;
} */

hr {
  @apply border border-teritory-300 my-5;
}

.vl {
  @apply border border-teritory-200 top-0 left-1/2 mr-5 mb-3;
  height: 100px;
}

.d-none {
  display: none;
}

.light-gray-bg {
  @apply bg-card-100;
  height: calc(100% - 266px);
}

.smoke-white-bg {
  @apply bg-card-200;
}

/* DISABLED */
/* button:disabled {
  @apply hover:bg-transparent text-font-disabled bg-teritory-200 border border-teritory-100 hover:border-teritory-100 shadow-none;
  color: #aaa !important;
} */

/* Components page temporary */
.components-list-wrap {
  @apply max-w-screen-xlg mx-auto p-6 bg-gray-50;
}
.components-list-card {
  @apply p-6 rounded mb-4 bg-white;
}

/* Forms & Questionnaires Start here */
.search-block {
  @apply grid grid-cols-1 md:grid-cols-2 md:gap-5;
}
.sort-and-filter {
  @apply w-full sm:w-auto;
  min-width: 150px;
}
@media (min-width: 576px) {
  .sort-and-filter {
    max-width: 160px;
  }
}
.sort-filter-block {
  @apply flex justify-start mt-5 md:justify-end gap-5 md:mt-0 flex-wrap xs:flex-nowrap;
}
.fav-icon {
  @apply cursor-pointer;
}

/* hr line start here */
.hr-line {
  @apply relative my-8 border border-secondary-100;
  /* height: 1px;
  border: 1px solid var(--input); */
}
.hr-line.full-line {
  @apply -mx-10;
}
.hr-line label {
  @apply absolute left-0 -top-8 font-proximanovaRegular font-bold text-md text-font-dark;
}
.hr-line a {
  @apply absolute right-0 -top-8 font-medium;
}

.hr-line h4 {
  @apply absolute left-0 -top-12 text-font-light;
}

.hr-line h4 {
  @apply text-lg font-bold tracking-wider text-font-light;
}
/* hr line end here */

/* link menu start here */

.link-menu {
  @apply flex justify-end;
}
.link-menu span {
  @apply text-primary-200 underline text-base cursor-pointer font-medium pl-4;
  z-index: 1;
}
.link-menu span.setting_back_focus {
  @apply text-font-light mt-10;
}
/* link menu end here */

/* form css start here */
.fm-btn-group {
  @apply flex gap-8 justify-center s:justify-end flex-wrap pb-11;
}
.qb-card-group {
  @apply grid grid-cols-1 xx:grid-cols-2 gap-5 pb-12;
}
form.create-form .form-input:first-child {
  @apply mt-0;
}
form.create-form .input-group {
  @apply my-6;
}
.form-create-body {
  @apply grid grid-cols-1 lg:grid-cols-1 lg:max-w-full;
}

.add-create-body {
  @apply bg-card-100;
}

/* form css end here */
.recent-data-block .empty--full {
  min-height: 160px;
}
.empty--full {
  @apply flex;
}
.empty-data-block {
  @apply bg-transparent px-5 py-10 m-auto flex items-center justify-center;
  min-height: 75px;
  max-width: 580px;
  width: 90%;
}
.empty-data-block p {
  @apply text-center;
}
.left-btn-group {
  @apply flex gap-5;
}
.accord-count:empty {
  display: none;
}
.accord-count {
  @apply ml-2 bg-primary-100 text-white flex items-center justify-center text-sl rounded-full;
  width: 28px;
  height: 28px;
}

.disabled-preview {
  pointer-events: none;
}

.email-add-block .tag-input {
  @apply mb-5;
  min-height: 100px;
  max-height: 150px;
  overflow: auto;
}

.schedule-action-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.newform-text {
  color: red;
  text-align: center;
  padding-bottom: 10px;
  justify-content: center;
}
.error-text {
  color: red !important;
}
.filter_block {
  @apply mb-6;
}
.filter_card {
  @apply border border-primary-100 p-5 grid grid-cols-3 gap-x-2;
}
.filter_date_block {
  @apply border border-primary-100 p-5 grid grid-cols-2 gap-5;
}
.filter_block .heading {
  @apply text-base text-font-dark font-bold mb-3 block;
}
.filter-link {
  @apply text-teritory-300 font-bold text-sm cursor-pointer;
}

.selected_rec_list {
  @apply grid grid-cols-5 gap-4 p-4;
}

.selected_rec_list:nth-child(odd) {
  @apply bg-card-200;
}

.multi_btn_center {
  @apply grid justify-center gap-4;
  grid-template-columns: max-content;
}

.multi_btn_center button {
  @apply m-0 !important;
}

.selected_rec_table {
  @apply relative w-full rounded overflow-auto min-h-0 max-h-screen mb-6;
  -webkit-overflow-scrolling: touch;
}

.selected_rec_body {
  @apply relative table;
  width: 1000px;
}

@media (max-width: 600px) {
  .react-datepicker__month-container {
    width: 256px;
  }
  .react-datepicker__time-container {
    width: 256px !important;
  }
  .react-datepicker {
    width: 256px;
  }
  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 0px !important;
  }
}

@media screen and (max-width: 800px) and (orientation: landscape) {
  .popup-card.xlg {
    @apply overflow-auto;
    height: 85vh;
  }
  .popup-card.xlg .popup-body {
    @apply overflow-y-visible !important;
  }
}

@media screen and (max-width: 568px) and (orientation: portrait) {
  .popup-card.xlg {
    @apply overflow-auto;
    height: 85vh;
  }
  .popup-card.xlg .popup-body {
    @apply overflow-y-visible !important;
  }
}

.range-label-md-block {
  @apply block bg-teritory-300 p-5 mt-5;
}

.range-label-md-block span {
  @apply block mb-4 text-base text-font-text font-proximanovaRegular;
}

.range-label-md-block span:last-child {
  @apply mb-0;
}

.search-loading {
  justify-content: space-between;
  display: flex;
}

.show-bulleting > ol {
  list-style: revert !important;
}
.show-bulleting > ul {
  list-style: revert !important;
}

.upload_text_block {
  text-align: center;
  cursor: pointer;
}
.upload_text_block p {
  font-size: 14px;
  font-style: italic;
}

.inner-container {
  min-height: 625px;
}
.neither-safe-content {
  font-style: italic;
  color: #000000 !important;
}
.neither-safe-inner-content {
  font-size: 17px !important;
  color: #000 !important;
  font-weight: 900 !important;
}
.multi-apt-block .location-blk {
  @apply flex mb-4;
}
.multi-apt-block {
  @apply grid gap-5;
}
.multi-apt-block .dbcard-footer {
  @apply grid-cols-1;
}
.call-icon-blk {
  @apply block mr-2;
}
.apt-time-blk {
  @apply flex justify-end;
}
.multi-appt-icon svg {
  color: #1a6aa2;
}
.apt-day-blk {
  /* replaced the css by sezhian */
  @apply grid grid-cols-2;
}
.multi-apt-block .description {
  @apply flex !mb-4;
}
.multi-apt-block .overall-spacing span {
  @apply !flex;
}
.multi-apt-block .read-more-btn {
  @apply cursor-pointer px-0 py-0 text-sm font-proximanovaRegular font-bold text-primary-100;
}
.multi-apt-block .read-more-btn:hover {
  @apply underline;
}
.multi-apt-block span {
  @apply !cursor-default;
}
.multi-apt-block .multi-appt-desc {
  @apply !text-sm !mb-0;
}
.multi-apt-block .multi-appt-desc.more {
  @apply h-[80px] overflow-auto;
}
/* .popup-body .full-white.overflow-auto {
  @apply !h-auto;
} */
.accordion-wrap .safeguarding {
  @apply pb-[30px];
}

.accordion-wrap .safe-sub-text {
  @apply -mt-[30px] font-bold;
}

.delete-user-popup-blk .popup-bg-header {
  @apply px-0 py-6;
}

.card-completion {
  @apply flex justify-between flex-nowrap;
}

.card-completion h5 {
  @apply max-w-64 w-full;
}

/* .responses-view-block div:empty{
  display: none;
} */

.pad-0 {
  padding: 0px !important;
}
.disabled-link {
  color: #999 !important;
  pointer-events: none;
}
.btn-disable {
  font-family: "proxima-nova-Regular", sans-serif !important;
  text-transform: none !important;
  color: rgb(156 156 156) !important;
  pointer-events: none;
}
