/* Header Nav */
.header-block {
  @apply relative bg-primary-100;
  min-height: 220px;
}
.header-nav {
  @apply hidden md:flex;
  min-height: 39px;
}
.header-nav-list {
  @apply flex-1;
}
.header-nav-list a {
  @apply font-bold text-base text-font-light flex justify-center items-center h-full w-full no-underline;
}
.header-img {
  @apply absolute top-0 left-0;
  width: 165px;
  height: 70px;
}
.logo-cav {
  @apply absolute top-0 left-0;
  width: 100px;
}
.profile-section {
  @apply flex justify-end;
}
.menu-block {
  @apply flex items-center;
}
.bento-menu-block {
  @apply hidden md:block cursor-pointer;
}
.header-info-section {
  @apply pb-10 mx-auto;
  max-width: 80%;
  width: 90%;
}
.header-info-section h1,
.header-info-section p {
  @apply font-normal text-font-light mb-3;
}
/* Header Nav End */
.primary-nav {
  @apply bg-primary-200;
}
.primary-nav .nav-list {
  @apply relative flex items-center overflow-y-hidden overflow-x-scroll w-full sm:justify-evenly justify-start;
  min-height: 55px;
}
.primary-nav .nav-item {
  @apply flex text-center p-2 text-base;
}
.primary-nav .nav-list a {
  @apply text-teritory-300 font-SanchezsemiBold text-base font-bold hover:text-secondary-400;
}

.primary-nav .nav-list .active {
  @apply relative font-bold text-secondary-400;
}
.second-nav{
  @apply border-t-white border-t bg-teritory-100;
}
.secondary-nav {
  @apply bg-secondary-100;
}
.secondary-nav .nav-list {
  @apply relative flex items-center w-full overflow-x-scroll overflow-y-hidden;
  min-height: 55px;
}
.secondary-nav .nav-item {
  @apply flex items-center text-secondary-200 font-proximanovaRegular text-sm sm:text-base text-center p-2;
  min-height: 55px;
}
.secondary-nav .active {
  @apply relative font-bold text-secondary-200;
}
.secondary-nav .active:after {
  @apply absolute left-0 right-0 -bottom-0.5 sm:-bottom-px ml-auto mr-auto h-0 w-0;
  content: '';
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 10px solid;
  border-top: 0;
}
.nav-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.primary-nav .nav-item .li{
  @apply mb-0;
}