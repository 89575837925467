.choosetags-group {
  @apply inline-block relative mb-3 cursor-pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.choosetags-group {
  @apply mr-3;
}
.choosetags-group:last-child {
  @apply mr-0;
}
.choosetags-group input {
  @apply opacity-0 absolute cursor-pointer h-0 w-0;
}
.choose-checkmark {
  @apply flex px-3 items-center justify-center text-sm font-proximanovaRegular font-bold border border-primary-100 rounded-lg text-primary-100;
  height: 32px;
  min-width: 100px;
}
.choosetags-group input:checked ~ .choose-checkmark {
  @apply border shadow-lg bg-primary-200 border-teritory-200 text-font-light;
}
.choosetags-group.choose-yes-no input:checked ~ .choose-checkmark {
  @apply bg-primary-100 text-white;
}

.choosetags-group input:disabled ~ .choose-checkmark {
  @apply bg-font-disabled shadow-none text-font-100 border-font-100;
}
.choosetags-group input:disabled:checked ~ .choose-checkmark {
  @apply bg-font-disabled border-font-disabled shadow-none;
}

.choice-new .choosetags-group input:disabled:checked ~ .choose-checkmark {
  @apply text-primary-100 border-primary-100;
}

.choose-tag-body {
  @apply flex flex-wrap overflow-y-scroll;
}

.css-1s2u09g-control {
  background-color: transparent !important;
}
.css-26l3qy-menu {
  z-index: 60 !important;
}
/* Select Tag */

/* @responsive {
  .css-yk16xz-control {
    @apply bg-white border border-input !important;
  }
  .css-1rhbuit-multiValue {
    @apply bg-primary-100 flex px-3 items-center justify-center text-sm font-montserrat font-bold border-none rounded-lg border-Check-primary-true !important;
    height: 32px;
    min-width: 100px;
  }
  .css-12jo7m5 {
    @apply text-font-light !important;
  }
  .css-xb97g8:hover {
    @apply bg-transparent !important;
  }
  .css-xb97g8 svg {
    @apply fill-current text-font-light !important;
  }
} */
