.btn {
  @apply h-[50px] text-base py-2 px-4 font-semibold tracking-wide cursor-pointer focus:outline-none;
  text-decoration: none !important;
}

.exit-btn {
  @apply bg-secondary-400 w-[80%] text-font-300 text-base font-proximanovaBold uppercase px-0;
  border-radius: 5px;
}

.primary-btn,
.clientdb-btn {
  @apply bg-secondary-400  min-w-[150px] font-proximanovaBold text-font-300 uppercase sm:text-base text-sm;
  border-radius: 5px;
}

.clientdb-btn {
  @apply h-[40px] min-w-[80px] !important;
}

.service-btn {
  @apply bg-secondary-400 w-[100%] font-proximanovaBold text-font-300 mb-4 uppercase;
  border-radius: 5px;
  font-size: 16px;
}

.primary-btn:hover,
.clientdb-btn:hover {
  @apply font-proximanovaBold opacity-100 bg-font-200 text-font-100 border-font-300 border-[1px] uppercase;
}

.primary-btn:active,
.clientdb-btn:active {
  @apply bg-secondary-400 min-w-[150px] font-proximanovaBold text-font-300 uppercase;
  border-radius: 5px;
}
.btn.primary-btn.dropchkbtn.triage-btn-disable {
  @apply font-proximanovaBold bg-teritory-300 text-teritory-200 cursor-not-allowed;
}
.btn.primary-btn.dropchkbtn.triage-btn-disable:hover {
  @apply border-none;
}
:disabled {
  @apply bg-teritory-300 min-w-[150px] font-proximanovaRegular text-teritory-200 capitalize !important;
  border-radius: 5px;
}

:disabled path {
  stroke: grey;
  stroke-width: 1px;
}

/* .disabled {
    @apply hover:bg-transparent text-font-disabled bg-slate-400 border border-primary-400 shadow-none hover:border-primary-400;
  } */

.submit-btn {
  @apply bg-secondary-400 min-w-fit font-proximanovaBold text-font-300 uppercase;
  border-radius: 5px;
  font-size: 16px;
}

.submit-btn:active {
  @apply bg-secondary-400 min-w-fit font-proximanovaBold text-font-300 uppercase;
  border-radius: 5px;
  font-size: 16px;
}

.submit-btn:hover {
  @apply bg-font-200 min-w-fit font-proximanovaBold text-font-300 uppercase border-font-300 border-[1px];
  border-radius: 5px;
  font-size: 16px;
}

.secondary-btn {
  @apply min-w-[50%] bg-font-200  text-font-300 border-font-300 border-[1px] sm:text-base text-sm uppercase;
}

.toppregnancy-btn {
  @apply min-w-[240px] opacity-100 bg-secondary-400 text-font-300 border-font-300 border-[1px] font-proximanovaBold text-base uppercase;
  border-radius: 5px;
}

.toppregnancy-btn:hover {
  @apply min-w-[240px] opacity-100 bg-font-200 text-font-300 border-font-300 border-[1px] font-proximanovaBold text-base uppercase;
  border-radius: 5px;
}

.toppregnancy-btn:active {
  @apply min-w-[240px] opacity-100 bg-secondary-400 text-font-300 border-font-300 border-[1px] font-proximanovaBold text-base uppercase;
  border-radius: 5px;
}

.teritory-btn {
  @apply min-w-[150px] opacity-100 bg-font-200 text-font-300 border-font-300 border-[1px] font-proximanovaBold sm:text-base text-sm uppercase;
  border-radius: 5px;
}

.teritory-btn:active {
  @apply min-w-[150px] opacity-100 bg-font-200 text-font-300 border-font-300 border-[1px] font-proximanovaBold uppercase;
  border-radius: 5px;
}

.teritory-btn:hover {
  @apply min-w-[150px] opacity-100 bg-secondary-400 text-font-300 border-none font-proximanovaBold uppercase;
  border-radius: 5px;
}

/* .user-btn {
  @apply bg-transparent w-[100%] text-font-100 border-[1px] border-input;
} */

.light-btn {
  @apply bg-primary-400 w-[100%] text-font-100 border-[1px] border-primary-100 ml-5 uppercase;
}

.btn-center {
  @apply grid justify-items-center my-8 uppercase;
}

.w-60 {
  @apply w-[60%] !important;
}

.w-50 {
  @apply w-[50%] !important;
}

.approve-btn {
  @apply bg-primary-100 min-w-[50%] font-proximanovaBold text-font-light uppercase h-[35px] py-1 pl-1 pr-1 xx:text-sm text-xs !important;
  border-radius: 5px;
}

.reject-btn {
  @apply bg-transparent min-w-[50%]  mr-3 font-proximanovaBold text-font-text uppercase h-[35px] border border-font-300 py-1 pl-1 pr-1 xx:text-sm text-xs !important;
  border-radius: 5px;
}

#pagination-first-page:disabled,
#pagination-previous-page:disabled,
#pagination-next-page:disabled,
#pagination-last-page:disabled {
  @apply min-w-0 !important;
}

.option-link {
  @apply text-primary-100 font-proximanovaBold text-base pr-0 pl-0 min-w-0 cursor-pointer !important;
  text-transform: inherit !important;
}

.right-btn-group :disabled {
  @apply bg-teritory-300  min-w-fit font-proximanovaBold text-teritory-200 uppercase rounded-md text-base !important;
}

.assigntrigger {
  @apply text-teritory-400 font-proximanovaBold text-base pr-0 pl-0 min-w-0 cursor-pointer !important;
  text-transform: inherit !important;
}

.dropchkbtn {
  @apply bg-secondary-400 min-w-[30px] h-[35px] font-proximanovaBold text-font-300 uppercase text-sm my-2;
  border-radius: 5px;
}

.dropchkbtn:hover {
  @apply font-proximanovaBold opacity-100 bg-font-200 text-font-100 border-font-300 border-[1px] uppercase min-w-[30px] h-[35px];
}

.dropchkbtn:active {
  @apply bg-secondary-400 min-w-[30px] h-[35px] font-proximanovaBold text-font-300 uppercase;
  border-radius: 5px;
}

.dropchkbtn:disabled {
  @apply min-w-[30px] h-[35px] uppercase text-sm my-2 !important;
}
.audit-btn {
  @apply bg-secondary-400 h-[42px] font-proximanovaBold text-font-300 uppercase text-sm px-20;
  border-radius: 5px;
}

@media (max-width: 800px) {
  .exit-btn {
    @apply pl-10;
  }
}

.drop-disabled {
  @apply cursor-none;
}
.w-fix {
  @apply w-[250px] !important;
}
.email-UnCaptialize {
  text-transform: none !important;
}
