/* .group-input {
    @apply relative;
  }
  
  .group-input label {
    @apply text-sm block mb-1 font-semibold text-left tracking-wide text-font-dark mt-4;
  }
  
  .group-input p {
    @apply text-base block mb-3;
  }
  
  .group-input .field-text {
    @apply cursor-pointer relative border py-2 px-4 border-input bg-primary-100 text-sm tracking-wide text-black w-full mb-2;
    box-sizing: border-box;
    letter-spacing: 1px;
  }

  .group-input .field-text:focus {
    @apply outline-none border border-input;
  }
  .validate {
     @apply absolute right-2 top-2;
  } */
.react-select__control {
  @apply w-[100%] h-[48%] border-[1px] border-secondary-100 text-base text-font-300 p-2 outline-none  cursor-pointer !important;
  border-radius: 0.5rem !important;
}

.react-select__value-container {
  @apply p-0 !important;
}

.react-select__input-container {
  @apply m-0 p-0 !important;
}

.group-input {
  @apply relative mb-6;
}
.group-input input {
  @apply w-[100%] h-[48%] border-[1px] border-secondary-100 text-base text-font-300 pt-4 pb-3 pr-3 pl-4 outline-none;
  border-radius: 0.5rem;
}
.group-input input:hover {
  @apply border-primary-200;
}

.group-input input:focus {
  @apply border-primary-200;
}

.group-input label {
  @apply text-secondary-100 pl-2 pr-2 cursor-text text-base block text-left tracking-wide bg-white absolute left-4 top-4;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
}

/* 
Text Anomation
*/

.field-text:focus ~ label.input-label,
.field-text:not(:placeholder-shown).field-text:not(:focus) + * {
  @apply text-base top-[-0.7rem] left-[0.8rem] text-primary-200 border-primary-200;
}

.field-text:not(:placeholder-shown).field-text:not(:focus) {
  @apply border-primary-200;
}

.group-input.error .field-text:not(:placeholder-shown).field-text:not(:focus),
.group-input.error
  .field-text:not(:placeholder-shown).field-text:not(:focus)
  ~ .input-label {
  @apply border-secondary-300 text-secondary-300;
}

.password-input {
  @apply top-[17px] absolute items-center h-full z-0 w-[40px] h-[20px] right-0 bottom-0 cursor-pointer;
}

.react-tel-input .form-control {
  @apply w-[100%] border-primary-200 text-font-300 py-4 pr-4 pl-14 !important;
}

.react-tel-input .special-label {
  @apply text-primary-200 !important;
}

.error .form-control {
  @apply border-secondary-300 !important;
}

.error .react-tel-input .special-label {
  @apply text-secondary-300 !important;
}

.aw-error {
  @apply text-secondary-300 mb-4 mt-2 text-base;
}

.aw-error span {
  @apply text-secondary-300 mb-4 mt-2 text-base font-proximanovaBold;
}

.aw-error label {
  @apply text-secondary-300 mb-4 mt-2 text-base font-proximanovaRegular;
}

.sec-error label {
  @apply text-secondary-300 mb-4 mt-2 text-base font-proximanovaBold;
}

.sec-warning label {
  @apply text-font-warning mb-4 mt-2 text-base font-proximanovaBold;
}

.sec-success label {
  @apply text-secondary-600 font-proximanovaBold;
}

.top-error {
  @apply text-secondary-300 pb-4 pr-4 pl-4 text-base;
}

.vas-error {
  @apply text-secondary-300 pb-4 pr-4 pl-4 text-[14px];
}

.appointment-error {
  @apply text-secondary-300 pb-4 pr-4 pl-4 text-[16px];
}

.error .field-text {
  border: 1px solid #d93175 !important;
}
