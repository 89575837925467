.overall-spacing h1 {
  @apply font-SanchezsemiBold text-2xl font-extrabold text-primary-200 mt-4;
}

.overall-qbspacing h1 {
  @apply font-SanchezsemiBold text-2xl font-extrabold text-font-text mt-4;
}

.line {
  @apply w-[50px] border-primary-200 border-b-[3px] mb-6 mt-[30px];
}

.qbline {
  @apply w-[50px] border-font-text border-b-[2px] mb-6 mt-[30px];
}

.overall-spacing p {
  @apply tracking-wide font-proximanovaRegular text-base text-font-300 mb-8;
}

.overall-spacing a {
  @apply font-proximanovaBold text-primary-100 text-base cursor-pointer;
}

.overall-spacing a:hover {
  @apply font-proximanovaBold text-primary-100 text-base cursor-pointer underline;
}

.tcblock h1 {
  @apply font-proximanovaBold text-md text-font-300;
}

.w-25 {
  @apply w-[25%] !important;
}

.w-auto {
  @apply w-[auto] !important;
}

.scroll-container {
  @apply max-h-[450px] overflow-auto md:pr-8 md:pl-8 md:pb-8 pb-8 pr-8;
}
.tc-footer-blk {
  @apply grid gap-5 grid-cols-[repeat(auto-fit,minmax(170px,max-content))] justify-center md:mb-0 mb-10;
}
.error-txt p {
  @apply text-secondary-300 mb-2 text-base font-proximanovaRegular;
}

.overall-center {
  min-height: calc(100vh - 143px);
}

.none {
  @apply hidden !important;
}

.listext li {
  @apply mb-0;
}

.pswdvalidation ul {
  @apply tracking-wide font-proximanovaRegular text-base text-secondary-100 mb-8;
}

.pswdvalidation p {
  @apply tracking-wide font-proximanovaRegular text-base text-teritory-200 mb-1;
}

.listext span {
  @apply font-proximanovaBold text-base text-secondary-100 cursor-pointer !important;
}

.overall-spacing span {
  @apply font-proximanovaRegular text-base text-font-300 cursor-pointer;
}

.overall-radio {
  @apply bg-teritory-300 mb-5 rounded-xl p-4;
}

.security-radio {
  @apply mb-5 rounded-xl pb-8;
}

.radio-border {
  @apply border-b border-teritory-200;
}

.overall-radio h6,
.security-radio h6 {
  @apply text-base font-proximanovaBold tracking-wide text-font-300 p-4;
}

.pin-secure h6 {
  @apply text-base font-proximanovaBold tracking-wide text-font-300 py-4;
}

.overall-radio h5 {
  @apply text-base font-proximanovaBold tracking-wide text-primary-100 my-4 cursor-pointer;
}

.overall-radio p {
  @apply text-base mb-1 tracking-wide pb-4 pr-4 pl-4;
  font-size: 16px;
}

.header-text-booking h2 {
  @apply font-proximanovaBold text-base text-font-300;
}

.header-text-booking p {
  @apply tracking-wide font-proximanovaRegular text-base text-font-300 mb-4;
}

.header-text-booking li {
  @apply list-disc ml-4 text-font-300 tracking-wide font-proximanovaRegular text-base;
}

.header-text-booking ul {
  @apply mb-4;
}

.header-text-pregnancy p {
  @apply tracking-wide font-proximanovaBold text-base text-font-300 mb-8;
}

.overall-radio a {
  @apply font-proximanovaBold text-primary-100 text-base cursor-pointer underline;
}

.header-text-pregnancy a {
  @apply font-proximanovaBold text-primary-100 text-base cursor-pointer !important;
}

.header-text-pregnancy a:hover {
  @apply font-proximanovaBold text-primary-100 text-base cursor-pointer;
}

.overall-radio a:hover {
  @apply font-proximanovaBold text-primary-100 text-base cursor-pointer underline;
}

.link-para {
  @apply text-center font-proximanovaRegular text-base cursor-pointer text-primary-200 !important;
}

.recaptcha {
  @apply my-6;
}

.logout-text {
  @apply h-[50px] bg-white flex justify-end;
}

.logout-text a,
.secondary-header a {
  @apply font-proximanovaBold text-primary-200 text-base cursor-pointer float-right mt-2 mr-4;
}

.logout-text a:hover {
  @apply font-proximanovaBold text-primary-200 text-base cursor-pointer underline;
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  position: fixed;
  background-color: #555555ba;
  z-index: 100;
}

.overlay-fixed {
  z-index: 100;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  background-color: #555555ba;
}

.overlay.dark {
  background-color: #555;
}

.popup {
  top: 70%;
  left: 24%;
  right: 24%;
  transform: translateY(-50%);
  position: absolute;
  border-radius: 5px;
  z-index: 101;
}

.popup.loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  transform: none;
  border-radius: 5px;
  z-index: 101;
  display: flex;
}

.popup.loader .loading-center {
  @apply max-w-[600px] m-auto;
}

.blue-alert,
.phonealert {
  @apply bg-primary-200 text-font-200 text-base p-4 rounded-2xl;
}

.blue-alert p {
  @apply text-font-200 text-base font-proximanovaRegular mb-8 !important;
}

.phonealert p {
  @apply font-proximanovaBold text-font-200 text-base mb-8 !important;
}

.phonealert h2 {
  @apply font-proximanovaRegular text-font-200 text-base mb-8 !important;
}

.noralert p {
  @apply font-proximanovaBold text-font-200 text-base mb-0 !important;
}

.weekalert {
  @apply font-proximanovaBold text-secondary-400 text-base mt-8 underline !important;
}

.weekalert p {
  @apply font-proximanovaBold text-font-200 text-base mb-0 !important;
}

.blue-alert span {
  @apply text-font-200 text-base font-proximanovaBold;
}

.radiolink a {
  @apply text-primary-100 text-base cursor-pointer;
}

.radiolink a:hover {
  @apply text-primary-100 text-base cursor-pointer;
}

.radiolink {
  @apply my-4;
}

.text-message span {
  @apply font-proximanovaBold;
}

.pinlist ul {
  @apply list-disc m-0 py-4 px-8;
}

.pinlist li {
  @apply block;
}

.bottom-line {
  @apply border-b;
}

.dbgridcard {
  @apply grid s:grid-cols-2 grid-cols-1;
}

.db-semicard {
  @apply border flex flex-col border-primary-200 bg-primary-200 text-center px-4 py-5 m-[10px];
  border-radius: 20px;
}

.db-semicard h1 {
  @apply font-proximanovaBold text-center uppercase text-md text-font-200 mb-2 mt-0;
}

.db-semicard p {
  @apply font-proximanovaRegular text-sm text-font-200 mb-2;
}

.db-semicard a {
  @apply font-proximanovaRegular uppercase text-sm text-secondary-400 underline cursor-pointer;
}

.db-semicard a:hover {
  @apply text-sm;
}

.webchat-text span {
  @apply underline text-font-200 font-proximanovaBold !important;
}

.bold-text span {
  @apply font-proximanovaBold;
}

.booking-confirm p {
  @apply mb-0 !important;
}

.borderline {
  border-bottom-width: 0px !important;
}

.remove-mar{
  @apply mb-0 !important;
}
.address-disabled {
  @apply bg-teritory-300 min-w-[150px] cursor-auto font-proximanovaRegular text-teritory-200 capitalize !important;
  border-radius: 5px;
  
}