.footer-header {
  @apply flex h-16 bg-secondary-100 border-[0px];
}

.alert-container {
  @apply md:relative absolute right-0 w-[100%] bg-primary-300 py-5 pr-4 pl-4;
}

.unsetPosition {
  position: unset !important;
}

/* @media (min-height:700px){
    .alert-container {
       bottom:0;
    }
  } */
@media (max-width: 800px) {
  .unsetPosition {
    position: unset !important;
  }
}

.alertside {
  @apply flex grid-cols-2 gap-4 justify-center;
}

.alerticon {
  @apply flex items-center justify-center text-font-300;
}

.alerticon img {
  @apply min-w-[25px];
}

.alertext {
  @apply flex items-center justify-center tracking-wide font-proximanovaRegular text-base text-font-300;
}

.alertext p {
  @apply mb-0 text-font-300 !important;
}

.alertoverlay {
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #F5F5F5;
  opacity: 0.50;
  z-index: 11;
}

.alertpopup {
  top: 50vh;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 12;
  width: 100%;
}
.alert-block {
  @apply bg-primary-300 p-4;
  margin:0 -32px;
}
.alert-block p {
  @apply mb-0 !important;
}
.alert-absoulte {
  @apply absolute rounded-b -bottom-8;
}
.alertext-block {
  @apply tracking-wide font-proximanovaRegular text-base text-font-300;
}
.alertext-block p {
  @apply mb-0;
}