.primary-header {
  @apply flex w-full justify-between items-center px-4 relative z-10 border-b-[1px] border-secondary-100 min-h-[80px] bg-primary-200;
}

.secondary-header {
  @apply bg-white flex w-full justify-between items-center pl-4 z-10 border-b-[2px] min-h-[80px];
}

.secondary-header img {
  @apply w-[70px];
}

.primary-header h1 {
  @apply text-secondary-100 text-xl font-Sanchez;
}

.primary-header img {
  @apply mr-3 w-[35px] h-[35px] inline;
}

.btn-exit {
  @apply flex w-full justify-center items-center;
}
.menuside {
  @apply grid items-center grid-cols-2 gap-3 min-h-[80px];
}

.menuside div {
  @apply cursor-pointer;
}

.searchicon {
  @apply flex items-center justify-center h-[100%] border-r border-l border-teritory-400 pr-5 pl-5;
}

.menuicon {
  @apply flex items-center justify-center h-[100%] pr-5 pl-3;
}

.form-header-btn {
  @apply grid gap-5 xs:justify-end xmd:mt-0 mt-4 justify-start;
  grid-template-columns: repeat(auto-fit, minmax(170px, max-content));
}

.form-sort {
  @apply grid gap-5 xs:justify-end mt-8 md:mt-2 justify-start;
  grid-template-columns: repeat(auto-fit, minmax(170px, max-content));
}

.exit-btn img {
  @apply relative right-[115px];
}

/* .profile-border {
  @apply border-l-[1px] border-secondary-100;
 }

.account-blk {
  @apply flex mr-5 mb-6 mt-5 ml-4;
}

.account-blk p {
  @apply text-secondary-100 text-sm;
} */
